@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/variables/colors';

#continent-indicator {
	text-align: left;
	display: flex;
	height: 28px;

	.continentListItem {
		@include TextStyle(Body);
		@include MarginAreas(0, 1);
		@include PaddingAreas(0);
		color: $GreyD;
		cursor: pointer;
		border-bottom: 0.25rem solid transparent;
		height: auto;

		&.is-selected {
			color: $BrandBlue;
			border-bottom: 0.25rem solid $BrandBlue;
		}
	}
}
