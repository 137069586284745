@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '_colours', '_components';
@import 'button';
@import '~@studyportals/multiselect/dist/multiselect.css';

.GeotargetingTool {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@include MarginAreas(0);
	}

	ul {
		@include MarginAreas(0);
		@include PaddingAreas(0);
	}

	li {
		@include MarginAreas(0);
		@include PaddingAreas(0);
		list-style-type: none;
	}
}

#GeoTargetingMSWrapper {
	background: $White;
	padding: 0.75rem;
	border: 1px solid $GreyDD;
	display: inline-block;
}
