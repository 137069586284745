@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/spacing';

#CountryStateControl {
	@include PaddingAreas(1);
	height: 3rem;

	.SelectionDetailsWarningMessage {
		margin-right: -1rem;
	}
}
