@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/variables/colors';

#Final {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 550px;

	&.FinalSuccessPage {
		margin: 7rem auto;
	}

	&.FinalExitPage {
		margin: 4rem auto;
	}

	&.FinalErrorPage {
		margin: 3rem auto;
	}

	.GeoIcon {
		display: block;
		margin: 0;
		font-size: 10rem;
	}

	.MainHeading {
		@include TextStyle(Highlight);
		@include MarginAreas(1.5, 0, 0.5);
	}

	.SubHeading {
		@include TextStyle(Body);
		color: $GreyD;
	}

	.GeoHelperButton {
		margin: 1.5rem auto 0;

		&.LowPriority {
			margin: 0.5rem auto 0;
		}
	}
}
