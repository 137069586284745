@use 'sass:math';

@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-components/components/Button';

$BorderSize: math.div(1rem, 16) * 2;

#GeoTargetingMS {

	.NavigatorButton,
	.DriverButton {
		padding: 0.5rem - $BorderSize 1rem;
		min-height: auto;
	}
}

.GeoHelperButton {
	/* Total height of 40px. */
	$BorderSize: math.div(1rem, 16) * 2;

	display: block;
	padding: 0;
	background: transparent;
	border: 0;
	color: $BrandBlueL;
	font-weight: 600;
	cursor: pointer;

	&:visited {
		color: $BrandBlueL;
	}

	&:hover,
	&:focus {
		color: $BrandBlue;
		background: transparent;
		text-decoration: none;

		> span {
			border-color: $BrandBlue;
		}
	}

	> span {
		@include TextStyle(Body);
		display: block;
		border-bottom: $BorderSize solid transparent;
	}

	&.LowPriority {
		color: $Grey;

		&:visited {
			color: $Grey;
		}

		&:hover,
		&:focus {
			color: $Grey;
			background: transparent;

			> span {
				display: block;
				border-color: $Grey;
			}
		}
	}
}
