@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/variables/colors';

.PremiumLabelWrapper {
	position: relative;
	align-self: flex-start;
	margin-top: 0.2rem;

	.PremiumLabel {
		@include MarginAreas(0, 1, 0, 0);
		width: 60px;
		flex: 0 0 80px;
	}
}
