@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '_colours';

$DegreeShortColour: #76ab42;
$DegreeBachelorColour: #cc66ff;
$DegreeMasterColour: #ef5600;
$DegreePhdColour: #5ec5ff;

@mixin setScrollColour($colourBackground, $colourScroll) {
	/* IE specific scrollbar styling */
	scrollbar-track-color: $colourBackground;
	scrollbar-face-color: $colourScroll;
	scrollbar-arrow-color: $colourBackground;
	scrollbar-shadow-color: $colourBackground;
	scrollbar-highlight-color: $colourBackground;

	/* Webkit specific scrollbar styling */
	&::-webkit-scrollbar {
		background: $colourBackground;
		width: 0.5rem;
	}

	&::-webkit-scrollbar-thumb {
		background: $colourScroll;
	}
}

.ConfigurationBlock {
	border: solid 0.75rem $GreyLLL;
	background-color: $GreyLLL;
	height: 13.25rem;
}

.ConfigurationBlockList {
	@include setScrollColour($GreyLLL, $White);

	& {
		overflow-y: auto;
		max-height: 13.25rem;
	}

	.EntityTypeBlock {
		font-size: 0.6rem;
		line-height: 1rem;
		display: inline-block;
		box-sizing: border-box;
		vertical-align: middle;
		padding: 0.1rem 0.25rem;
		margin-right: 0.25rem;
		width: 6rem;
		text-transform: uppercase;
		text-align: center;
		background-color: $White;
	}

	.ConfigurationBlockOptionEntityDetails {
		@include TextStyle(Note);
		vertical-align: middle;

		.EntityTypeLabel {
			font-weight: 600;

			&.Short {
				color: $DegreeShortColour;
			}

			&.Bachelor {
				color: $DegreeBachelorColour;
			}

			&.Master {
				color: $DegreeMasterColour;
			}

			&.Phd {
				color: $DegreePhdColour;
			}
		}
	}

	.ConfigurationBlockOptionId {
		@include TextStyle(Note);
		font-style: italic;
	}

	&.WhiteBackground {
		@include setScrollColour($White, $GreyLLL);

		.EntityTypeBlock {
			background-color: $GreyLLL;
		}
	}
}

.CountryIndicator {
	@include TextStyle(Note);
	background: $BrandBlue;
	color: $White;
	border-radius: 1rem;
	min-width: 0.5rem;
	padding: 0 0.3rem;
	height: 1rem;
	position: absolute;
	text-align: center;
	right: 0.6rem;
	top: -0.4rem;

	&.NoCountries {
		background: $GreyD;
	}
}

.SelectionDetailsBlock {
	display: flex;
	flex: 1;
	flex-direction: row;
	background-color: $GreyLLL;
	align-items: center;

	.SelectionDetailsCounterBlock {
		display: flex;
		flex-direction: row;
		align-items: center;

		.SelectionDetailsCounterNumber {
			@include TextStyle(Highlight);
			@include MarginAreas(0, 1, 0, 0);
			text-align: center;
			height: 3rem;
			width: 3rem;
			background-color: $BrandBlue;
			color: $White;
			line-height: 3rem;
			border-radius: 50%;

			&.NoSelection {
				background-color: $GreyD;
			}
		}
	}
}

.SelectionDetailsWarningMessage {
	@include PaddingAreas(1);
	font-size: 0;
	text-align: left;
	background-color: $GreyLLL;
	width: calc(55% - 0.5rem);
	align-items: center;
	height: 3rem;
	border-left: 1rem solid $White;

	.SelectionWarningIcon {
		@include MarginAreas(0, 1, 0, 0);
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		font-size: 1.5rem;
		width: 1.5rem;
		color: $GeoRed;
	}

	span {
		@include TextStyle(Note);
		width: calc(100% - 3rem);
		display: inline-block;
		vertical-align: middle;
	}
}

.ShortcutsBlock {
	@include TextStyle(Note);
	@include PaddingAreas(0, 0, 0, 1);
	display: flex;
	text-align: left;

	.Shortcut {
		color: $BrandBlueL;
		cursor: pointer;

		&:not(:last-child)::after {
			@include MarginAreas(0, 1);
			content: '|';
			color: $GreyDD;
		}
	}
}
