@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/mixins/typography';

.OverviewTab {
	@include MarginAreas(0.5, 0, 0);
	width: 100%;
	text-align: left;

	.OverviewTabHeader {
		@include TextStyle(Note);
		@include PaddingAreas(0.5);
		background-color: $GreyLLL;
		cursor: pointer;

		.OverviewTabHeaderTitle {
			@include TextStyle(Title100);
		}
	}

	.OverviewTabChevron {
		@include TextStyle(Title100);
		display: inline-block;
		float: right;
	}

	.OverviewTabContent {
		@include TextStyle(Note);
		@include PaddingAreas(0.5, 0.5, 0);
		display: none;

		&.CountryTargetingContent {
			font-size: 0;
			padding: 0.75rem 0.5rem 0;
		}
	}

	.TargetingTab {
		@include TextStyle(Note);
		@include MarginAreas(0, 0, 0.5);
		@include PaddingAreas(0.25, 0);
		position: relative;
		display: inline-block;
		box-sizing: border-box;
		width: 25%;
		border: 1px solid $GreyLLL;
		text-align: center;
		text-transform: uppercase;
		cursor: pointer;

		&:not(:first-of-type) {
			border-left: none;
		}

		&.Selected {
			background-color: $GreyLLL;
		}
	}

	.NoCountriesTargetedText {
		@include TextStyle(Note);
	}

	.ConfigurationBlockList {
		max-height: 18.5rem;
	}

	.ConfigurationBlockOption {
		line-height: 1.5rem;
	}

	&.Selected {
		.OverviewTabChevron {
			transform: rotate(90deg);
		}

		.OverviewTabContent {
			display: block;
		}
	}
}
