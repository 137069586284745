@import '~@studyportals/styles-abstracts/mixins/spacing';

#CountryManagement {
	@include MarginAreas(1.5, 0, 0);
	position: relative;

	.Clear {
		@include MarginAreas(0.5, 0, 0);
	}
}
