@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';

#EntitySelection {
	text-align: left;

	.ConfigurationBlockOption {
		display: block;

		.ConfigurationBlockOptionLabel {
			cursor: pointer;
			line-height: 1.5rem;
		}

		.ConfigurationBlockOptionCheckbox {
			vertical-align: middle;
		}
	}

	.TopEntityShortcutsBlock {
		@include MarginAreas(0.5, 0, 2);
	}

	.SelectionDetailsBlock {
		@include MarginAreas(0, 0, 1);
		height: 5rem;
		align-items: center;

		.SelectionDetailsCounterBlock {
			@include PaddingAreas(1);
			width: 45%;
			height: 3rem;

			.SelectionDetailsCounterNumber {
				text-align: center;
			}
		}
	}
}
