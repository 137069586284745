@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

#GeoTargetingMS .SearchCountriesBarWrapper {
	position: absolute;
	right: 0;
	top: -1.25rem;

	.SearchCountriesButton {
		padding: 0.9rem 1rem;
		line-height: 0.7rem;
	}

	.SearchCountriesBar {
		@include PaddingAreas(1);
		height: 3rem;
		border-radius: 3px;
		box-sizing: border-box;
		transition: width 0.2s ease-in-out;

		&.Condensed {
			width: 3rem;
		}

		&.Expanded {
			width: 12rem;
		}
	}

	.ClearCountriesSearchButton {
		position: absolute;
		right: 0.5rem;
		top: 0.75rem;
		font-size: 1.5rem;
		cursor: pointer;
	}
}
