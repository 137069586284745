@import '~@studyportals/styles-abstracts/abstracts.scss';

.MultiSelectGroupLabel {
	display: flex;
	align-items: center;
	height: 40px;
	box-sizing: border-box;
	@include PaddingAreas(0, 0.5);
	@include MarginAreas(0);

	@include TextStyle(Body);
	color: $Grey;
}
