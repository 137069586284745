@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/variables/colors';

.GeotargetingTool .countryListItem {
	@include TextStyle(Note);
	display: flex;
	margin: 0.2rem 0;
	width: 33%;
	align-items: center;

	.Checkbox {
		margin: auto 0.3rem;
	}

	.countryLabel {
		display: flex;
		align-items: center;
	}

	.CountryItem {
		margin: 0 0.2rem;
		max-width: 170px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&.is-selected {
			color: $BrandBlueL;
		}
	}

	.PremiumLabel {
		@include TextStyle(Note);
	}
}
