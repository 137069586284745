@import '~@studyportals/styles-abstracts/mixins/spacing';

#OverviewList {
	display: flex;
	flex-direction: column-reverse;

	.PremiumRow {
		@include MarginAreas(0.5, 0);
		display: flex;
		align-items: flex-start;
		justify-content: start;
		position: relative;
	}

	.LevelWrapper {
		display: flex;
		flex-direction: column;
	}
}
