@import '~@studyportals/styles-abstracts/abstracts.scss';

.MultiSelectSelectedOption {
	position: relative;

	display: inline-flex;
	align-items: center;
	height: 30px;
	box-sizing: border-box;
	@include PaddingAreas(0, 2, 0, 0.5);
	@include MarginAreas(0, 0.5, 0.5, 0);

	@include TextStyle(Body);
	color: $GreyDD;

	background: $GreyLL;

	border-radius: 15px;
	border: 1px solid $GreyLL;

	&:hover {
		cursor: pointer;
		text-decoration: line-through;
		border: 1px solid $GreyD;
	}

	&:focus {
		outline: none;
	}
}

.MultiSelectSelectedOptionDelete {
	position: absolute;
	right: 0;
	top: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 30px;
	@include MarginAreas(0, 0, 0, 0.5);

	cursor: pointer;
}
