@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Apply box-shadow based on a desired percepted height of the element
 * @param {String} $Height
 */
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset text styles
 * @param {String} $Style
 */
/**
 * Get the areas values in rems based on the four dimensions.
 * The minimum amount of parameters is one and the maximum is four,
 * and they correspond with dimensions similarly as how they do
 * for the margin and padding css rules in general.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectGroupLabel {
  display: flex;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  padding:  0 0.5rem;
  margin:  0;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: normal;
  color: #547A92;
}