@import '~@studyportals/styles-abstracts/mixins/spacing';

#country-selection {

	.CountryList {
		@include MarginAreas(0.5, 0, 0);
	}

	.Shortcuts {
		@include MarginAreas(0.5, 0, 1.5);
	}
}
