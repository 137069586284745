@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/variables/colors';

#Close {
	@include PaddingAreas(0);
	background: transparent;
	border: 0;
	position: absolute;
	top: 0.1rem;
	right: 0;

	.CrossIcon {
		font-size: 1.1rem;
		color: $Grey;
	}
}
