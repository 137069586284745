@import '~@studyportals/styles-abstracts/mixins/spacing';

#SetSelectedCountryLevel {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 55%;

	.SetLevelListItem {
		@include MarginAreas(0);
		@include PaddingAreas(0);
		display: flex;
		align-items: center;
		list-style: none;
		height: 3rem;

		.PremiumButton {
			height: 100%;
			cursor: pointer;
		}
	}
}
