@import '~@studyportals/styles-abstracts/abstracts.scss';

.MultiSelectOption {
	display: flex;
	align-items: center;
	min-height: 40px;
	box-sizing: border-box;
	@include PaddingAreas(0.5);
	@include MarginAreas(0);

	text-align: left;
	@include TextStyle(Body);
	color: $GreyDD;

	cursor: pointer;

	&:hover {
		background-color: $GreyLL;
	}

	&.is-disabled {
		color: $GreyD;

		&:hover {
			background-color: $White;
			cursor: not-allowed;
		}
	}

	.MultiSelectOptionIcon {
		@include MarginAreas(0, 0.5, 0, 0);

		font-size: 1rem;
	}
}
