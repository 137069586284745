@import '~@studyportals/styles-abstracts/variables/colors';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';

#PricingSelection {

	.SelectionDetailsWarningMessage {
		@include PaddingAreas(1.5, 1);
		box-sizing: border-box;
		position: absolute;
		bottom: 4rem;
		height: 5rem;
		width: 100%;
		border: 0;
	}

	.PricingProductsMultiSelect {
		@include PaddingAreas(0.5, 0, 0);
	}
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {

	.SelectionDetailsWarningMessage {
		display: none;
	}
}
