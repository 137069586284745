@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/mixins/typography';

#country-list {
	height: 14.5rem;

	.ConfigurationBlockList {
		display: flex;
		flex-wrap: wrap;
		max-height: 14.5rem;

		.NoCountriesFoundText {
			@include TextStyle(Note);
			@include MarginAreas(0.5);
		}
	}
}
