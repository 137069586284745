@import './../../styles/mixins';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';

#Header {
	text-align: left;

	.TopBar {
		display: flex;
		justify-content: space-between;
		padding: 0 0 0.75rem 0;
	}

	.Title {
		@include TextStyle(Title100);
		@include PaddingAreas(0);
		line-height: 1.5rem;
		font-weight: 400;
	}

	.VerticalLine {
		@include verticalLine;
	}

	.Subtitle {
		@include TextStyle(Note);
		@include PaddingAreas(0.5, 0, 0);
		display: inline;
		font-weight: 400;

		&.SubtitleInvisible {
			visibility: hidden;
		}
	}
}
