@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/mixins/typography';
@import './../../styles/mixins';

#Overview {
	margin-top: -0.5rem;
	text-align: left;

	.OverviewTabsWrapper {
		height: 28rem;
	}

	.VerticalLine {
		@include verticalLine;
	}

	.SelectionDetailsWarningMessage {
		@include PaddingAreas(0.5);
		background-color: transparent;
		box-sizing: border-box;
		width: 100%;
		height: 2.5rem;
		border-left: 0;

		.SelectionWarningIcon {
			@include MarginAreas(0, 0.5, 0, 0);
			width: 2rem;
		}
	}
}
