@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/mixins/spacing';
@import '~@studyportals/styles-abstracts/variables/colors';

// the ms has less space int this view.
#GeoServiceWrapper {

	#GeoTargetingMS {
		width: 820px;
		height: auto;
	}
}

.ListingOverview {

	.ListingGeoTargetingMessage {
		@include MarginAreas(4, 0, 1);
		text-align: left;
	}

	.OverviewList {
		width: 100%;
	}

	.ManageGeoContainer {
		@include MarginAreas(2, 0, 0);

		.ManageGeo {
			@include TextStyle(Body);
			color: $BrandBlue;
			text-decoration: none;

			&:hover {
				color: $BrandBlueD;
			}
		}
	}
}
