@import '~@studyportals/styles-abstracts/mixins/spacing';

#Navigation {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;

	&.OnEntitySelectionScreen {
		justify-content: flex-start;

		.NavigatorButton {
			@include MarginAreas(0, 1, 0, 0);
			transition: none;
		}
	}
}
