@import '~@studyportals/styles-abstracts/mixins/typography';
@import '~@studyportals/styles-abstracts/variables/colors';

.premiumLevel {
	@include TextStyle(Note);
	background-color: $White;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	float: left;
	margin: 0 0.2rem;
	padding: 0.1rem 0.3rem;
	line-height: 1.2rem;
	text-align: center;
	text-transform: uppercase;
}
