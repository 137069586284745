@import '~@studyportals/styles-abstracts/mixins/spacing';

#EntitySelectionFilters {
	@include MarginAreas(0, 0, 0.5);

	.EntitySelectionFilter {
		display: inline-block;
		width: 16rem;

		& + .EntitySelectionFilter {
			@include MarginAreas(0, 0, 0, 1);
		}
	}
}
