@import '~@studyportals/styles-abstracts/variables/_colors.scss';
@import '~@studyportals/styles-abstracts/mixins/spacing';

.LoadingWrapper {
	height: 100%;
	background-color: $White;
	position: relative;

	.LoadingContent {
		position: absolute;
		width: 13rem;
		margin: 0 auto;
		left: 0;
		right: 0;
		top: calc(50% - 6.375rem);
		text-align: center;

		.LoadingText {
			display: block;
		}
	}

	.LoadingProgressBarWrapper {
		@include MarginAreas(0.5, 0, 0);
		background-color: $GreyLLL;
		box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3);
		width: 12rem;
		height: 0.4rem;
		border-radius: 3px;
		overflow: hidden;

		.LoadingProgressBar {
			display: block;
			width: 100%;
			margin-top: -1px;
			height: 0.5rem;
			appearance: none;

			&::-moz-progress-bar {
				background-color: $BrandBlue;
			}

			&::-webkit-progress-value {
				background-color: $BrandBlue;
				transition: width 0.1s;
			}

			&::-webkit-progress-bar {
				background-color: transparent;
			}
		}
	}
}
