@import 'styles/main';
@import '../../node_modules/@studyportals/styles-abstracts/variables/colors';
@import url(https://i.icomoon.io/public/d33d268649/Studyportalslineariconset/style.css);
@import '~@studyportals/styles-abstracts/mixins/spacing';

// Style the power popup
.PowerPopupWrapper[data-popup-name='geoPopup'] .PowerPopup {
	max-width: calc(880px + 2 * 0.75rem);

	.CloseButton {
		display: none;
	}
}

#GeoTargetingMS,
#GeoTargetingMS button {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

#GeoTargetingMS {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: $GreyDD;
	background-color: $White;
	width: 880px;
	height: 592px;

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.slide-left-enter-active,
	.slide-left-leave-active,
	.slide-right-enter-active,
	.slide-right-leave-active {
		transition-duration: 0.5s;
		transition-property: height, opacity, transform;
		transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
		overflow: hidden;
	}

	.slide-left-enter-from,
	.slide-right-leave-to {
		opacity: 0;
		transform: translate(2em, 0);
	}

	.slide-left-leave-to,
	.slide-right-enter-from {
		opacity: 0;
		transform: translate(-2em, 0);
	}
}

#GeoPage {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.RouterView {
	@include MarginAreas(1, 0, 0);
}
