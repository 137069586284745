@import '~@studyportals/styles-abstracts/mixins/spacing';

#ExistingPresetBasedSelection {
	@include PaddingAreas(0, 0.5, 0, 0);
	max-height: 28rem;

	.OverviewTab {
		display: inline-block;
		width: calc(100% - 3rem);

		&:first-of-type {
			@include MarginAreas(0);
		}
	}

	.ExistingPresetBasedSelectionBox {
		@include MarginAreas(1, 0.5, 0, 0);
		@include PaddingAreas(0.25, 0.5, 0.5);
		display: inline-block;
		vertical-align: top;
		cursor: pointer;

		&:first-of-type {
			@include MarginAreas(0.5, 0.5, 0, 0);
		}

		.ExistingPresetBasedSelectionRadioButton {
			@include MarginAreas(0);
			cursor: pointer;
		}
	}
}
